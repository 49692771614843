<template>
  <div>
    <jf-layout>
      <template slot="head">
        <el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-admin:tenant:save')" @click="add">新增</el-button>
        <el-button size="small" type="primary" icon="el-icon-download" v-if="isAuth('platform-admin:tenant:export')" @click="exportFile">导出</el-button>
        <el-form class="form" :inline="true">

          <el-form-item label="">
            <el-input v-model="params.companyName" placeholder="企业名称" @keydown.enter.native="search" @clear="search" clearable size="small"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="params.username" placeholder="管理员账号"  @keydown.enter.native="search"  @clear="search" clearable size="small"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="params.biller" placeholder="开单人"  @keydown.enter.native="search" @clear="search" clearable size="small"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="params.createName" placeholder="创建人"  @keydown.enter.native="search" @clear="search" clearable size="small"></el-input>
          </el-form-item>
          <template v-if="moreOption">

            <el-form-item label="">
              <el-select clearable v-model="params.status" @change="search" placeholder="状态"  size="small">
                <el-option :value="null" label="全部"></el-option>
                <el-option :value="0" label="冻结"></el-option>
                <el-option :value="1" label="正常"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="areaId">
              <el-cascader placeholder="所在地区" clearable v-model="areaIds" size="small" @change="areaChange" :options="area" :show-all-levels="false" :props="{checkStrictly: true , expandTrigger: 'hover',label:'label',value:'id'}">
              </el-cascader>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="params.industrysId" size="small" clearable placeholder="所属行业" @change="industrysIdChange">
                <el-option v-for="item in industry" :key="item.id" :label="item.industryName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="params.industrysManageId" clearable size="small" @change="$forceUpdate();search()" placeholder="经营类型">
                <el-option v-for="item in industrysManage" :key="item.id" :label="item.industryName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="params.softwareConnector"  @keydown.enter.native="search" @clear="search" placeholder="对接人"  clearable size="small"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="params.receiverTelephone"  @keydown.enter.native="search" @clear="search" placeholder="对接人电话" clearable size="small"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="params.enterprisesOwners"  @keydown.enter.native="search" @clear="search" placeholder="负责人" clearable size="small"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="params.principalTelephone"  @keydown.enter.native="search" @clear="search" placeholder="负责人电话" clearable size="small"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker v-model="params.startDate" @change="search" value-format="yyyy-MM-dd" clearable placeholder="启用日期" size="small"></el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker v-model="params.expiryDate" @change="search" value-format="yyyy-MM-dd" clearable placeholder="到期日期" size="small"></el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-select clearable v-model="params.accountType" @change="search" placeholder="账户类型"  size="small">
                <el-option :value="0" label="企业账户"></el-option>
                <el-option :value="1" label="个人账户"></el-option>
              </el-select>
            </el-form-item>

          </template>

          <el-form-item>
            <span class="moreOption" @click="moreOption = !moreOption">{{moreOption?'收起更多条件':'展开更多条件'}}</span>
            <el-button size="small" type="primary" icon="el-icon-search" @click="search">查询</el-button>
          </el-form-item>
        </el-form>

      </template>

      <el-table v-loading="loading" slot="body" border :data="list" :height="600">
        <el-table-column align="center" type="selection" width="50" />
        <el-table-column align="center" prop="companyName" label="公司名称" />
        <el-table-column align="center" prop="username" label="管理员账号" />

        <el-table-column align="center" prop="industryName" label="所属行业" show-overflow-tooltip />

        <el-table-column align="center" prop="softwareConnector" label="对接人" />

        <el-table-column align="center" prop="receiverTelephone" label="对接电话" />
        <el-table-column align="center" prop="startDate" label="启动日期" />
        <el-table-column align="center" prop="expiryDate" label="到期日期" />
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="{row}">
            <el-tag v-if="row.status===0" type="info" size="small">已冻结</el-tag>
            <el-tag v-if="row.status===1" type="success" size="small">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="purchasersNumber" label="人数包" />
        <el-table-column align="center" prop="activeStaff" label="在职人数" />
        <el-table-column align="center" prop="remainingDays" label="剩余天数">
          <template slot-scope="{row}">
            <el-tag v-if="row.remainingDays<=0" type="danger" size="small">已过期</el-tag>
            <span v-else>{{row.remainingDays}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="biller" label="开单人" />
        <el-table-column align="center" prop="createName" label="创建人" />
        <el-table-column align="center" label="操作" width="350">
          <template slot-scope="{row}">
            <div class="btndiv">
              <el-button size="mini" @click="see(row)">查看</el-button>
              <el-button size="mini" v-if="row.hasInit===0&&isAuth('platform-admin:tenant:init')" @click="operation(row,'初始化')">初始化数据</el-button>
              <el-button size="mini" v-if="isAuth('platform-admin:tenant:resetPassword')" @click="operation(row,'重置密码')">重置密码</el-button>
              <el-button size="mini" v-if="isAuth('platform-admin:tenant:login')" @click="login(row)">登录
              </el-button>
              <el-button size="mini" @click="edit(row)" v-if="isAuth('platform-admin:tenant:update')">编辑
              </el-button>
              <el-button size="mini" v-if="row.hasInit===1 && row.status===1&&isAuth('platform-admin:tenant:status')" @click="operation(row,'冻结')" type="danger">冻结</el-button>
              <el-button size="mini" v-if="row.hasInit===1 && row.status===0&&isAuth('platform-admin:tenant:status')" @click="operation(row,'解冻')" type="danger">解冻</el-button>
              <el-button size="mini" @click="openWeChatList(row)">第三方授权</el-button>
              <el-button size="mini" v-if="(row.hasInit===0 || row.status===0) &&isAuth('platform-admin:tenant:delete')" @click="remove(row)" type="danger">删除</el-button>
              <!-- 初始化成功之前，不允许显示”解冻“和”冻结“按钮。初始化成功后，不再显示”初始化“按钮，同时显示”解冻“按钮（冻结按钮和解冻按钮是互斥显示） -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <jf-page slot="foot" @change="getList" :page.sync="params.page" :limit.sync="params.limit" :total="total" />
    </jf-layout>
    <weChatDetails :show.sync="showWeChat" :tenantCode="activeTenantCode"></weChatDetails>
  </div>
</template>

<script>
import weChatDetails from './weChat/details.vue'
import {residentUrl} from "@/config";
export default {
  components: {
    weChatDetails
  },
  data() {
    return {
      show: false,
      params: {
        page: 1,
        limit: 10,
        orderField: "create_date",
        order: "desc",
        biller: "",
        createName: ""
      },
      list: [],
      loading: false,
      row: {},
      total: 0,
      selectList: [],
      // 选择的地区ids
      areaIds: [],
      // 地区选项
      area: [],
      // 行业选项-1级
      industry: [],
      // 行业选项-2级
      industrysManage: [],
      activeTenantCode: null,
      showWeChat: false, //是否开启企业微信的弹窗
      moreOption: false
    };
  },
  created() {
    this.getArea();
    this.getIndustry();
    this.getList();
  },
  methods: {
    // 添加行业类型名称
    addIndustrysName() {
      this.list.forEach((element) => {
        for (var i = 0; i < this.industry.length; i++) {
          if (element.industrysId === this.industry[i].id) {
            this.$set(element, "industryName", this.industry[i].industryName);
            console.log(element.industryName);
            break;
          }
        }
      });
    },
    // 打开企业微信弹窗
    openWeChatList(row) {
      this.activeTenantCode = row.tenantCode;
      this.showWeChat = true;
    },
    // 选择行业
    industrysIdChange(val) {
      this.params.industrysManageId = null;
      this.resetIndustrysManage(val);
    },
    //重置二级选项
    resetIndustrysManage(id) {
      this.industrysManage = [];
      var arr = this.industry.filter((item) => {
        return item.id === id;
      });
      if (arr.length) {
        this.industrysManage = arr[0].children;
      } else {
        this.industrysManage = [];
      }
    },

    // 获取行业选项
    async getIndustry() {
      var res = await this.$get("/platform-config/industry/tree");
      if (res && res.code === 0) {
        this.industry = this.clearTreeData(res.data);
      }
      this.addIndustrysName();
    },
    // 清除级联选择器最后一空级，递归判断列表，把最后的children设为undefined
    clearTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.clearTreeData(data[i].children);
        }
      }
      return data;
    },
    // 组装树型数据
    setTree(treeData, parentId) {
      var treeArr = [];
      for (var i = 0; i < treeData.length; i++) {
        var node = treeData[i];
        if (node.pid == parentId) {
          var newNode = {
            id: node.id,
            label: node.name,
            children: this.setTree(treeData, node.id),
          };
          treeArr.push(newNode);
        }
      }
      return treeArr;
    },
    // 获取地区选项
    async getArea() {
      var res = await this.$get("/platform-admin/region/tree");
      if (res && res.code === 0) {
        var arr = this.setTree(res.data, "0");
        this.area = this.clearTreeData(arr);
        console.log(arr);
      }
    },
    // 选择地区回调
    areaChange(val) {
      this.params.provinceId = val[0] || "";
      this.params.cityId = val[1] || "";
      this.params.areaId = val[2] || "";
    },
    // 导出
    exportFile() {
      this.$get("/platform-admin/community/export");
    },
    search() {
      this.params.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      var res = await this.$get("/platform-admin/community/page", this.params);
      this.loading = false;
      if (res && res.code === 0) {
        this.list = res.data.list;
        this.addIndustrysName();
        this.total = res.data.total;
      }
    },
    see(row) {
      this.$router.push({
        path: "/resident-detail",
        query: {
          id: row.id,
        },
      });
    },
    add() {
      this.$router.push({
        path: "/resident-add",
      });
    },
    edit(row) {
      this.$router.push({
        path: "/resident-add",
        query: {
          id: row.id,
        },
      });
    },
    // 登录企业端
    login(row) {
      this.$confirm("此操作将跳转至居民端, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$get(`/platform-admin/community/login/${row.id}`);
          if (res && res.code === 0) {
            console.log(res);
            var token = res.data;
            if (token) {
              // 打开新的企业端重定向页面(传参:token\公司id)
              window.open(`${this.$residentUrl}?t=${token}&id=${row.id}`);
            } else {
              this.$message.warning("无法获取凭证");
            }
          }
        })
        .catch(() => { });
    },
    //操作
    operation(row, type) {
      var url;
      var msg;
      if (type === "冻结") {
        url = "/platform-admin/community/disable";
        msg = "此操作将冻结该用户, 是否继续?";
      }
      if (type === "解冻") {
        url = "/platform-admin/community/enable";
        msg = "此操作将解冻该用户, 是否继续?";
      }

      if (type === "初始化") {
        url = "/platform-admin/community/init";
        msg = "此操作将对用户数据进行初始化, 是否继续?";
      }

      if (type === "重置密码") {
        url = "/platform-admin/community/resetPassword";
        msg = "此操作将对用户密码进行重置, 是否继续?";
      }

      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$put(url, [row.tenantCode]);
          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: type + "成功!",
            });
            this.getList();
          }
        })
        .catch(() => { });
    },
    remove(row) {
      this.$confirm('此操作将永久删除该企业, 是否继续?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$del('/platform-admin/community', [row.tenantCode]);
          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.search();
          }
        })
        .catch(() => { });
    }
  },
};
</script>
<style lang="scss" scoped>
.btndiv /deep/ .el-button.el-button--mini {
  padding: 5px 7px;
}
.moreOption {
  color: #409eff;
  cursor: pointer;
  margin-right: 10px;
}
</style>
